<template>
    <section class="efficiency-total" v-if="status === 'success'">
        <h2 class="sr-only">Данные отчета "Доходы"</h2>
        <div class="efficiency-total__base" v-if="modeId === 'base'">
            <div class="efficiency-total__box box">
                <table class="efficiency-total__table efficiency-total__table_striped">
                    <colgroup>
                        <col class="efficiency-total__table-column efficiency-total__table-column_title">
                        <col class="efficiency-total__table-column efficiency-total__table-column_average">
                        <col class="efficiency-total__table-column efficiency-total__table-column_average">
                        <col class="efficiency-total__table-column efficiency-total__table-column_average">
                        <col class="efficiency-total__table-column efficiency-total__table-column_average">
                        <col class="efficiency-total__table-column efficiency-total__table-column_average">
                        <col class="efficiency-total__table-column efficiency-total__table-column_average">
                        <col class="efficiency-total__table-column efficiency-total__table-column_average">
                        <col class="efficiency-total__table-column efficiency-total__table-column_average">
                        <col class="efficiency-total__table-column efficiency-total__table-column_average">
                        <col class="efficiency-total__table-column efficiency-total__table-column_average">
                        <col class="efficiency-total__table-column efficiency-total__table-column_average">
                        <col class="efficiency-total__table-column efficiency-total__table-column_average">
                        <col class="efficiency-total__table-column efficiency-total__table-column_average">
                        <col class="efficiency-total__table-column efficiency-total__table-column_average">
                        <col class="efficiency-total__table-column efficiency-total__table-column_average">
                    </colgroup>
                    <thead>
                        <tr class="efficiency-total__table-row efficiency-total__table-row_head">
                            <th class="efficiency-total__table-cell efficiency-total__table-cell_head efficiency-total__table-cell_sortable efficiency-total__table-cell_vat"
                                scope="col"
                                rowspan="2"
                                @click="setSorting('common', 'title')"
                            >
                                <span class="efficiency-total__table-value">Источник</span>
                                <svg-icon class="efficiency-total__table-icon"
                                    v-if="sorting.group === 'common' && sorting.field === 'title' && sorting.direction === 'ASC'"
                                    icon="chevron-up"
                                />
                                <svg-icon class="efficiency-total__table-icon"
                                    v-if="sorting.group === 'common' && sorting.field === 'title' && sorting.direction === 'DESC'"
                                    icon="chevron-down"
                                />
                            </th>
                            <th class="efficiency-total__table-cell efficiency-total__table-cell_tac efficiency-total__table-cell_head" scope="col" colspan="5">Доставка</th>
                            <th class="efficiency-total__table-cell efficiency-total__table-cell_tac efficiency-total__table-cell_head" scope="col" colspan="5">Зал</th>
                            <th class="efficiency-total__table-cell efficiency-total__table-cell_tac efficiency-total__table-cell_head" scope="col" colspan="5">С собой</th>
                            <th class="efficiency-total__table-cell efficiency-total__table-cell_tac efficiency-total__table-cell_head" scope="col" colspan="5">Самовынос</th>
                            <th class="efficiency-total__table-cell efficiency-total__table-cell_tac efficiency-total__table-cell_head" scope="col" colspan="5">Всего</th>
                        </tr>
                        <tr class="efficiency-total__table-row efficiency-total__table-row_head">
                            <th class="efficiency-total__table-cell efficiency-total__table-cell_tar efficiency-total__table-cell_text-small efficiency-total__table-cell_head efficiency-total__table-cell_sortable"
                                scope="col"
                                @click="setSorting('delivery', 'avg_guests_in_order')"
                            >
                                <span class="efficiency-total__table-text">Гостей на заказ</span>
                                <svg-icon class="efficiency-total__table-icon"
                                    v-if="sorting.group === 'delivery' && sorting.field === 'avg_guests_in_order' && sorting.direction === 'ASC'"
                                    icon="chevron-up"
                                />
                                <svg-icon class="efficiency-total__table-icon"
                                    v-if="sorting.group === 'delivery' && sorting.field === 'avg_guests_in_order' && sorting.direction === 'DESC'"
                                    icon="chevron-down"
                                />
                            </th>
                            <th class="efficiency-total__table-cell efficiency-total__table-cell_tar efficiency-total__table-cell_text-small efficiency-total__table-cell_head efficiency-total__table-cell_sortable"
                                scope="col"
                                @click="setSorting('delivery', 'avg_check_per_guest')"
                            >
                                <span class="efficiency-total__table-text">Чек на гостя</span>
                                <svg-icon class="efficiency-total__table-icon"
                                    v-if="sorting.group === 'delivery' && sorting.field === 'avg_check_per_guest' && sorting.direction === 'ASC'"
                                    icon="chevron-up"
                                />
                                <svg-icon class="efficiency-total__table-icon"
                                    v-if="sorting.group === 'delivery' && sorting.field === 'avg_check_per_guest' && sorting.direction === 'DESC'"
                                    icon="chevron-down"
                                />
                            </th>
                            <th class="efficiency-total__table-cell efficiency-total__table-cell_tar efficiency-total__table-cell_text-small efficiency-total__table-cell_head efficiency-total__table-cell_sortable"
                                scope="col"
                                @click="setSorting('delivery', 'avg_dish_per_guest')"
                            >
                                <span class="efficiency-total__table-text">Блюд на гостя</span>
                                <svg-icon class="efficiency-total__table-icon"
                                    v-if="sorting.group === 'delivery' && sorting.field === 'avg_dish_per_guest' && sorting.direction === 'ASC'"
                                    icon="chevron-up"
                                />
                                <svg-icon class="efficiency-total__table-icon"
                                    v-if="sorting.group === 'delivery' && sorting.field === 'avg_dish_per_guest' && sorting.direction === 'DESC'"
                                    icon="chevron-down"
                                />
                            </th>
                            <th class="efficiency-total__table-cell efficiency-total__table-cell_tar efficiency-total__table-cell_text-small efficiency-total__table-cell_head efficiency-total__table-cell_sortable"
                                scope="col"
                                @click="setSorting('delivery', 'avg_dish_price')"
                            >
                                <span class="efficiency-total__table-text">Цена блюда</span>
                                <svg-icon class="efficiency-total__table-icon"
                                    v-if="sorting.group === 'delivery' && sorting.field === 'avg_dish_price' && sorting.direction === 'ASC'"
                                    icon="chevron-up"
                                />
                                <svg-icon class="efficiency-total__table-icon"
                                    v-if="sorting.group === 'delivery' && sorting.field === 'avg_dish_price' && sorting.direction === 'DESC'"
                                    icon="chevron-down"
                                />
                            </th>
                            <th class="efficiency-total__table-cell efficiency-total__table-cell_tar efficiency-total__table-cell_text-small efficiency-total__table-cell_head efficiency-total__table-cell_sortable"
                                scope="col"
                                @click="setSorting('delivery', 'avg_guests_in_order')"
                            >
                                <span class="efficiency-total__table-text">Заказов на курьера</span>
                                <svg-icon class="efficiency-total__table-icon"
                                    v-if="sorting.group === 'delivery' && sorting.field === 'avg_guests_in_order' && sorting.direction === 'ASC'"
                                    icon="chevron-up"
                                />
                                <svg-icon class="efficiency-total__table-icon"
                                    v-if="sorting.group === 'delivery' && sorting.field === 'avg_guests_in_order' && sorting.direction === 'DESC'"
                                    icon="chevron-down"
                                />
                            </th>

                            <th class="efficiency-total__table-cell efficiency-total__table-cell_tar efficiency-total__table-cell_text-small efficiency-total__table-cell_head efficiency-total__table-cell_sortable"
                                scope="col"
                                @click="setSorting('hall', 'avg_guests_in_order')"
                            >
                                <span class="efficiency-total__table-text">Гостей на заказ</span>
                                <svg-icon class="efficiency-total__table-icon"
                                    v-if="sorting.group === 'hall' && sorting.field === 'avg_guests_in_order' && sorting.direction === 'ASC'"
                                    icon="chevron-up"
                                />
                                <svg-icon class="efficiency-total__table-icon"
                                    v-if="sorting.group === 'hall' && sorting.field === 'avg_guests_in_order' && sorting.direction === 'DESC'"
                                    icon="chevron-down"
                                />
                            </th>
                            <th class="efficiency-total__table-cell efficiency-total__table-cell_tar efficiency-total__table-cell_text-small efficiency-total__table-cell_head efficiency-total__table-cell_sortable"
                                scope="col"
                                @click="setSorting('hall', 'avg_check_per_guest')"
                            >
                                <span class="efficiency-total__table-text">Чек на гостя</span>
                                <svg-icon class="efficiency-total__table-icon"
                                    v-if="sorting.group === 'hall' && sorting.field === 'avg_check_per_guest' && sorting.direction === 'ASC'"
                                    icon="chevron-up"
                                />
                                <svg-icon class="efficiency-total__table-icon"
                                    v-if="sorting.group === 'hall' && sorting.field === 'avg_check_per_guest' && sorting.direction === 'DESC'"
                                    icon="chevron-down"
                                />
                            </th>
                            <th class="efficiency-total__table-cell efficiency-total__table-cell_tar efficiency-total__table-cell_text-small efficiency-total__table-cell_head efficiency-total__table-cell_sortable"
                                scope="col"
                                @click="setSorting('hall', 'avg_dish_per_guest')"
                            >
                                <span class="efficiency-total__table-text">Блюд на гостя</span>
                                <svg-icon class="efficiency-total__table-icon"
                                    v-if="sorting.group === 'hall' && sorting.field === 'avg_dish_per_guest' && sorting.direction === 'ASC'"
                                    icon="chevron-up"
                                />
                                <svg-icon class="efficiency-total__table-icon"
                                    v-if="sorting.group === 'hall' && sorting.field === 'avg_dish_per_guest' && sorting.direction === 'DESC'"
                                    icon="chevron-down"
                                />
                            </th>
                            <th class="efficiency-total__table-cell efficiency-total__table-cell_tar efficiency-total__table-cell_text-small efficiency-total__table-cell_head efficiency-total__table-cell_sortable"
                                scope="col"
                                @click="setSorting('hall', 'avg_dish_price')"
                            >
                                <span class="efficiency-total__table-text">Цена блюда</span>
                                <svg-icon class="efficiency-total__table-icon"
                                    v-if="sorting.group === 'hall' && sorting.field === 'avg_dish_price' && sorting.direction === 'ASC'"
                                    icon="chevron-up"
                                />
                                <svg-icon class="efficiency-total__table-icon"
                                    v-if="sorting.group === 'hall' && sorting.field === 'avg_dish_price' && sorting.direction === 'DESC'"
                                    icon="chevron-down"
                                />
                            </th>
                            <th class="efficiency-total__table-cell efficiency-total__table-cell_tar efficiency-total__table-cell_text-small efficiency-total__table-cell_head efficiency-total__table-cell_sortable"
                                scope="col"
                                @click="setSorting('hall', 'avg_guests_in_order')"
                            >
                                <span class="efficiency-total__table-text">Заказов на курьера</span>
                                <svg-icon class="efficiency-total__table-icon"
                                    v-if="sorting.group === 'hall' && sorting.field === 'avg_guests_in_order' && sorting.direction === 'ASC'"
                                    icon="chevron-up"
                                />
                                <svg-icon class="efficiency-total__table-icon"
                                    v-if="sorting.group === 'hall' && sorting.field === 'avg_guests_in_order' && sorting.direction === 'DESC'"
                                    icon="chevron-down"
                                />
                            </th>

                            <th class="efficiency-total__table-cell efficiency-total__table-cell_tar efficiency-total__table-cell_text-small efficiency-total__table-cell_head efficiency-total__table-cell_sortable"
                                scope="col"
                                @click="setSorting('take', 'avg_guests_in_order')"
                            >
                                <span class="efficiency-total__table-text">Гостей на заказ</span>
                                <svg-icon class="efficiency-total__table-icon"
                                    v-if="sorting.group === 'take' && sorting.field === 'avg_guests_in_order' && sorting.direction === 'ASC'"
                                    icon="chevron-up"
                                />
                                <svg-icon class="efficiency-total__table-icon"
                                    v-if="sorting.group === 'take' && sorting.field === 'avg_guests_in_order' && sorting.direction === 'DESC'"
                                    icon="chevron-down"
                                />
                            </th>
                            <th class="efficiency-total__table-cell efficiency-total__table-cell_tar efficiency-total__table-cell_text-small efficiency-total__table-cell_head efficiency-total__table-cell_sortable"
                                scope="col"
                                @click="setSorting('take', 'avg_check_per_guest')"
                            >
                                <span class="efficiency-total__table-text">Чек на гостя</span>
                                <svg-icon class="efficiency-total__table-icon"
                                    v-if="sorting.group === 'take' && sorting.field === 'avg_check_per_guest' && sorting.direction === 'ASC'"
                                    icon="chevron-up"
                                />
                                <svg-icon class="efficiency-total__table-icon"
                                    v-if="sorting.group === 'take' && sorting.field === 'avg_check_per_guest' && sorting.direction === 'DESC'"
                                    icon="chevron-down"
                                />
                            </th>
                            <th class="efficiency-total__table-cell efficiency-total__table-cell_tar efficiency-total__table-cell_text-small efficiency-total__table-cell_head efficiency-total__table-cell_sortable"
                                scope="col"
                                @click="setSorting('take', 'avg_dish_per_guest')"
                            >
                                <span class="efficiency-total__table-text">Блюд на гостя</span>
                                <svg-icon class="efficiency-total__table-icon"
                                    v-if="sorting.group === 'take' && sorting.field === 'avg_dish_per_guest' && sorting.direction === 'ASC'"
                                    icon="chevron-up"
                                />
                                <svg-icon class="efficiency-total__table-icon"
                                    v-if="sorting.group === 'take' && sorting.field === 'avg_dish_per_guest' && sorting.direction === 'DESC'"
                                    icon="chevron-down"
                                />
                            </th>
                            <th class="efficiency-total__table-cell efficiency-total__table-cell_tar efficiency-total__table-cell_text-small efficiency-total__table-cell_head efficiency-total__table-cell_sortable"
                                scope="col"
                                @click="setSorting('take', 'avg_dish_price')"
                            >
                                <span class="efficiency-total__table-text">Цена блюда</span>
                                <svg-icon class="efficiency-total__table-icon"
                                    v-if="sorting.group === 'take' && sorting.field === 'avg_dish_price' && sorting.direction === 'ASC'"
                                    icon="chevron-up"
                                />
                                <svg-icon class="efficiency-total__table-icon"
                                    v-if="sorting.group === 'take' && sorting.field === 'avg_dish_price' && sorting.direction === 'DESC'"
                                    icon="chevron-down"
                                />
                            </th>
                            <th class="efficiency-total__table-cell efficiency-total__table-cell_tar efficiency-total__table-cell_text-small efficiency-total__table-cell_head efficiency-total__table-cell_sortable"
                                scope="col"
                                @click="setSorting('take', 'avg_guests_in_order')"
                            >
                                <span class="efficiency-total__table-text">Заказов на курьера</span>
                                <svg-icon class="efficiency-total__table-icon"
                                    v-if="sorting.group === 'take' && sorting.field === 'avg_guests_in_order' && sorting.direction === 'ASC'"
                                    icon="chevron-up"
                                />
                                <svg-icon class="efficiency-total__table-icon"
                                    v-if="sorting.group === 'take' && sorting.field === 'avg_guests_in_order' && sorting.direction === 'DESC'"
                                    icon="chevron-down"
                                />
                            </th>

                            <th class="efficiency-total__table-cell efficiency-total__table-cell_tar efficiency-total__table-cell_text-small efficiency-total__table-cell_head efficiency-total__table-cell_sortable"
                                scope="col"
                                @click="setSorting('pickup', 'avg_guests_in_order')"
                            >
                                <span class="efficiency-total__table-text">Гостей на заказ</span>
                                <svg-icon class="efficiency-total__table-icon"
                                    v-if="sorting.group === 'pickup' && sorting.field === 'avg_guests_in_order' && sorting.direction === 'ASC'"
                                    icon="chevron-up"
                                />
                                <svg-icon class="efficiency-total__table-icon"
                                    v-if="sorting.group === 'pickup' && sorting.field === 'avg_guests_in_order' && sorting.direction === 'DESC'"
                                    icon="chevron-down"
                                />
                            </th>
                            <th class="efficiency-total__table-cell efficiency-total__table-cell_tar efficiency-total__table-cell_text-small efficiency-total__table-cell_head efficiency-total__table-cell_sortable"
                                scope="col"
                                @click="setSorting('pickup', 'avg_check_per_guest')"
                            >
                                <span class="efficiency-total__table-text">Чек на гостя</span>
                                <svg-icon class="efficiency-total__table-icon"
                                    v-if="sorting.group === 'pickup' && sorting.field === 'avg_check_per_guest' && sorting.direction === 'ASC'"
                                    icon="chevron-up"
                                />
                                <svg-icon class="efficiency-total__table-icon"
                                    v-if="sorting.group === 'pickup' && sorting.field === 'avg_check_per_guest' && sorting.direction === 'DESC'"
                                    icon="chevron-down"
                                />
                            </th>
                            <th class="efficiency-total__table-cell efficiency-total__table-cell_tar efficiency-total__table-cell_text-small efficiency-total__table-cell_head efficiency-total__table-cell_sortable"
                                scope="col"
                                @click="setSorting('pickup', 'avg_dish_per_guest')"
                            >
                                <span class="efficiency-total__table-text">Блюд на гостя</span>
                                <svg-icon class="efficiency-total__table-icon"
                                    v-if="sorting.group === 'pickup' && sorting.field === 'avg_dish_per_guest' && sorting.direction === 'ASC'"
                                    icon="chevron-up"
                                />
                                <svg-icon class="efficiency-total__table-icon"
                                    v-if="sorting.group === 'pickup' && sorting.field === 'avg_dish_per_guest' && sorting.direction === 'DESC'"
                                    icon="chevron-down"
                                />
                            </th>
                            <th class="efficiency-total__table-cell efficiency-total__table-cell_tar efficiency-total__table-cell_text-small efficiency-total__table-cell_head efficiency-total__table-cell_sortable"
                                scope="col"
                                @click="setSorting('pickup', 'avg_dish_price')"
                            >
                                <span class="efficiency-total__table-text">Цена блюда</span>
                                <svg-icon class="efficiency-total__table-icon"
                                    v-if="sorting.group === 'pickup' && sorting.field === 'avg_dish_price' && sorting.direction === 'ASC'"
                                    icon="chevron-up"
                                />
                                <svg-icon class="efficiency-total__table-icon"
                                    v-if="sorting.group === 'pickup' && sorting.field === 'avg_dish_price' && sorting.direction === 'DESC'"
                                    icon="chevron-down"
                                />
                            </th>
                            <th class="efficiency-total__table-cell efficiency-total__table-cell_tar efficiency-total__table-cell_text-small efficiency-total__table-cell_head efficiency-total__table-cell_sortable"
                                scope="col"
                                @click="setSorting('pickup', 'avg_guests_in_order')"
                            >
                                <span class="efficiency-total__table-text">Заказов на курьера</span>
                                <svg-icon class="efficiency-total__table-icon"
                                    v-if="sorting.group === 'pickup' && sorting.field === 'avg_guests_in_order' && sorting.direction === 'ASC'"
                                    icon="chevron-up"
                                />
                                <svg-icon class="efficiency-total__table-icon"
                                    v-if="sorting.group === 'pickup' && sorting.field === 'avg_guests_in_order' && sorting.direction === 'DESC'"
                                    icon="chevron-down"
                                />
                            </th>

                            <th class="efficiency-total__table-cell efficiency-total__table-cell_tar efficiency-total__table-cell_text-small efficiency-total__table-cell_head efficiency-total__table-cell_sortable"
                                scope="col"
                                @click="setSorting('total', 'avg_guests_in_order')"
                            >
                                <span class="efficiency-total__table-text">Гостей на заказ</span>
                                <svg-icon class="efficiency-total__table-icon"
                                    v-if="sorting.group === 'total' && sorting.field === 'avg_guests_in_order' && sorting.direction === 'ASC'"
                                    icon="chevron-up"
                                />
                                <svg-icon class="efficiency-total__table-icon"
                                    v-if="sorting.group === 'total' && sorting.field === 'avg_guests_in_order' && sorting.direction === 'DESC'"
                                    icon="chevron-down"
                                />
                            </th>
                            <th class="efficiency-total__table-cell efficiency-total__table-cell_tar efficiency-total__table-cell_text-small efficiency-total__table-cell_head efficiency-total__table-cell_sortable"
                                scope="col"
                                @click="setSorting('total', 'avg_check_per_guest')"
                            >
                                <span class="efficiency-total__table-text">Чек на гостя</span>
                                <svg-icon class="efficiency-total__table-icon"
                                    v-if="sorting.group === 'total' && sorting.field === 'avg_check_per_guest' && sorting.direction === 'ASC'"
                                    icon="chevron-up"
                                />
                                <svg-icon class="efficiency-total__table-icon"
                                    v-if="sorting.group === 'total' && sorting.field === 'avg_check_per_guest' && sorting.direction === 'DESC'"
                                    icon="chevron-down"
                                />
                            </th>
                            <th class="efficiency-total__table-cell efficiency-total__table-cell_tar efficiency-total__table-cell_text-small efficiency-total__table-cell_head efficiency-total__table-cell_sortable"
                                scope="col"
                                @click="setSorting('total', 'avg_dish_per_guest')"
                            >
                                <span class="efficiency-total__table-text">Блюд на гостя</span>
                                <svg-icon class="efficiency-total__table-icon"
                                    v-if="sorting.group === 'total' && sorting.field === 'avg_dish_per_guest' && sorting.direction === 'ASC'"
                                    icon="chevron-up"
                                />
                                <svg-icon class="efficiency-total__table-icon"
                                    v-if="sorting.group === 'total' && sorting.field === 'avg_dish_per_guest' && sorting.direction === 'DESC'"
                                    icon="chevron-down"
                                />
                            </th>
                            <th class="efficiency-total__table-cell efficiency-total__table-cell_tar efficiency-total__table-cell_text-small efficiency-total__table-cell_head efficiency-total__table-cell_sortable"
                                scope="col"
                                @click="setSorting('total', 'avg_dish_price')"
                            >
                                <span class="efficiency-total__table-text">Цена блюда</span>
                                <svg-icon class="efficiency-total__table-icon"
                                    v-if="sorting.group === 'total' && sorting.field === 'avg_dish_price' && sorting.direction === 'ASC'"
                                    icon="chevron-up"
                                />
                                <svg-icon class="efficiency-total__table-icon"
                                    v-if="sorting.group === 'total' && sorting.field === 'avg_dish_price' && sorting.direction === 'DESC'"
                                    icon="chevron-down"
                                />
                            </th>
                            <th class="efficiency-total__table-cell efficiency-total__table-cell_tar efficiency-total__table-cell_text-small efficiency-total__table-cell_head efficiency-total__table-cell_sortable"
                                scope="col"
                                @click="setSorting('total', 'avg_guests_in_order')"
                            >
                                <span class="efficiency-total__table-text">Заказов на курьера</span>
                                <svg-icon class="efficiency-total__table-icon"
                                    v-if="sorting.group === 'total' && sorting.field === 'avg_guests_in_order' && sorting.direction === 'ASC'"
                                    icon="chevron-up"
                                />
                                <svg-icon class="efficiency-total__table-icon"
                                    v-if="sorting.group === 'total' && sorting.field === 'avg_guests_in_order' && sorting.direction === 'DESC'"
                                    icon="chevron-down"
                                />
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr class="efficiency-total__table-row efficiency-total__table-row_body efficiency-total__table-row_pinned">
                            <td class="efficiency-total__table-cell efficiency-total__table-cell_text-medium efficiency-total__table-cell_body efficiency-total__table-cell_title">
                                <router-link class="link link_red"
                                    :to="{ name: 'EfficiencyRestaurant', params: { id: 'total' } }"
                                    title="Смотреть общую эффективность по всем ресторанам"
                                >
                                    Итого
                                </router-link>
                            </td>
                            <td class="efficiency-total__table-cell efficiency-total__table-cell_tar efficiency-total__table-cell_body">
                                {{reportEfficiency.total.delivery.avg_guests_in_order | floatFormat}}
                            </td>
                            <td class="efficiency-total__table-cell efficiency-total__table-cell_tar efficiency-total__table-cell_body">
                                {{reportEfficiency.total.delivery.avg_check_per_guest | currencyIntFormat}}
                            </td>
                            <td class="efficiency-total__table-cell efficiency-total__table-cell_tar efficiency-total__table-cell_body">
                                {{reportEfficiency.total.delivery.avg_dish_per_guest | floatFormat}}
                            </td>
                            <td class="efficiency-total__table-cell efficiency-total__table-cell_tar efficiency-total__table-cell_body">
                                {{reportEfficiency.total.delivery.avg_dish_price | currencyIntFormat}}
                            </td>
                            <td class="efficiency-total__table-cell efficiency-total__table-cell_tar efficiency-total__table-cell_body">
                                {{reportEfficiency.total.delivery.avg_orders_per_courier | floatFormat}}
                            </td>

                            <td class="efficiency-total__table-cell efficiency-total__table-cell_tar efficiency-total__table-cell_body">
                                {{reportEfficiency.total.hall.avg_guests_in_order | floatFormat}}
                            </td>
                            <td class="efficiency-total__table-cell efficiency-total__table-cell_tar efficiency-total__table-cell_body">
                                {{reportEfficiency.total.hall.avg_check_per_guest | currencyIntFormat}}
                            </td>
                            <td class="efficiency-total__table-cell efficiency-total__table-cell_tar efficiency-total__table-cell_body">
                                {{reportEfficiency.total.hall.avg_dish_per_guest | floatFormat}}
                            </td>
                            <td class="efficiency-total__table-cell efficiency-total__table-cell_tar efficiency-total__table-cell_body">
                                {{reportEfficiency.total.hall.avg_dish_price | currencyIntFormat}}
                            </td>
                            <td class="efficiency-total__table-cell efficiency-total__table-cell_tar efficiency-total__table-cell_body">
                                {{reportEfficiency.total.hall.avg_orders_per_courier | floatFormat}}
                            </td>

                            <td class="efficiency-total__table-cell efficiency-total__table-cell_tar efficiency-total__table-cell_body">
                                {{reportEfficiency.total.take.avg_guests_in_order | floatFormat}}
                            </td>
                            <td class="efficiency-total__table-cell efficiency-total__table-cell_tar efficiency-total__table-cell_body">
                                {{reportEfficiency.total.take.avg_check_per_guest | currencyIntFormat}}
                            </td>
                            <td class="efficiency-total__table-cell efficiency-total__table-cell_tar efficiency-total__table-cell_body">
                                {{reportEfficiency.total.take.avg_dish_per_guest | floatFormat}}
                            </td>
                            <td class="efficiency-total__table-cell efficiency-total__table-cell_tar efficiency-total__table-cell_body">
                                {{reportEfficiency.total.take.avg_dish_price | currencyIntFormat}}
                            </td>
                            <td class="efficiency-total__table-cell efficiency-total__table-cell_tar efficiency-total__table-cell_body">
                                {{reportEfficiency.total.take.avg_orders_per_courier | floatFormat}}
                            </td>

                            <td class="efficiency-total__table-cell efficiency-total__table-cell_tar efficiency-total__table-cell_body">
                                {{reportEfficiency.total.pickup.avg_guests_in_order | floatFormat}}
                            </td>
                            <td class="efficiency-total__table-cell efficiency-total__table-cell_tar efficiency-total__table-cell_body">
                                {{reportEfficiency.total.pickup.avg_check_per_guest | currencyIntFormat}}
                            </td>
                            <td class="efficiency-total__table-cell efficiency-total__table-cell_tar efficiency-total__table-cell_body">
                                {{reportEfficiency.total.pickup.avg_dish_per_guest | floatFormat}}
                            </td>
                            <td class="efficiency-total__table-cell efficiency-total__table-cell_tar efficiency-total__table-cell_body">
                                {{reportEfficiency.total.pickup.avg_dish_price | currencyIntFormat}}
                            </td>
                            <td class="efficiency-total__table-cell efficiency-total__table-cell_tar efficiency-total__table-cell_body">
                                {{reportEfficiency.total.pickup.avg_orders_per_courier | floatFormat}}
                            </td>

                            <td class="efficiency-total__table-cell efficiency-total__table-cell_tar efficiency-total__table-cell_body">
                                {{reportEfficiency.total.total.avg_guests_in_order | floatFormat}}
                            </td>
                            <td class="efficiency-total__table-cell efficiency-total__table-cell_tar efficiency-total__table-cell_body">
                                {{reportEfficiency.total.total.avg_check_per_guest | currencyIntFormat}}
                            </td>
                            <td class="efficiency-total__table-cell efficiency-total__table-cell_tar efficiency-total__table-cell_body">
                                {{reportEfficiency.total.total.avg_dish_per_guest | floatFormat}}
                            </td>
                            <td class="efficiency-total__table-cell efficiency-total__table-cell_tar efficiency-total__table-cell_body">
                                {{reportEfficiency.total.total.avg_dish_price | currencyIntFormat}}
                            </td>
                            <td class="efficiency-total__table-cell efficiency-total__table-cell_tar efficiency-total__table-cell_body">
                                {{reportEfficiency.total.total.avg_orders_per_courier | floatFormat}}
                            </td>
                        </tr>
                        <tr class="efficiency-total__table-row efficiency-total__table-row_body"
                            v-for="restaurant in reportRestaurants" :key="restaurant.id"
                        >
                            <th class="efficiency-total__table-cell efficiency-total__table-cell_text-medium efficiency-total__table-cell_body" scope="row">
                                <router-link class="link link_red"
                                    :to="{ name: 'EfficiencyRestaurant', params: { id: restaurant.id } }"
                                    :title="`Смотреть доход по ресторану ${restaurant.title}`">
                                    {{restaurant.title}}
                                </router-link>
                            </th>
                            <td class="efficiency-total__table-cell efficiency-total__table-cell_tar efficiency-total__table-cell_body">
                                <span class="efficiency-total__table-value">
                                    {{restaurant.delivery.avg_guests_in_order | floatFormat}}
                                </span>
                            </td>
                            <td class="efficiency-total__table-cell efficiency-total__table-cell_tar efficiency-total__table-cell_body">
                                <span class="efficiency-total__table-value">
                                    {{restaurant.delivery.avg_check_per_guest | currencyIntFormat}}
                                </span>
                            </td>
                            <td class="efficiency-total__table-cell efficiency-total__table-cell_tar efficiency-total__table-cell_body">
                                <span class="efficiency-total__table-value">
                                    {{restaurant.delivery.avg_dish_per_guest | floatFormat}}
                                </span>
                            </td>
                            <td class="efficiency-total__table-cell efficiency-total__table-cell_tar efficiency-total__table-cell_body">
                                <span class="efficiency-total__table-value">
                                    {{restaurant.delivery.avg_dish_price | currencyIntFormat}}
                                </span>
                            </td>
                            <td class="efficiency-total__table-cell efficiency-total__table-cell_tar efficiency-total__table-cell_body">
                                <span class="efficiency-total__table-value">
                                    {{restaurant.delivery.avg_orders_per_courier | floatFormat}}
                                </span>
                            </td>

                            <td class="efficiency-total__table-cell efficiency-total__table-cell_tar efficiency-total__table-cell_body">
                                <span class="efficiency-total__table-value">
                                    {{restaurant.hall.avg_guests_in_order | floatFormat}}
                                </span>
                            </td>
                            <td class="efficiency-total__table-cell efficiency-total__table-cell_tar efficiency-total__table-cell_body">
                                <span class="efficiency-total__table-value">
                                    {{restaurant.hall.avg_check_per_guest | currencyIntFormat}}
                                </span>
                            </td>
                            <td class="efficiency-total__table-cell efficiency-total__table-cell_tar efficiency-total__table-cell_body">
                                <span class="efficiency-total__table-value">
                                    {{restaurant.hall.avg_dish_per_guest | floatFormat}}
                                </span>
                            </td>
                            <td class="efficiency-total__table-cell efficiency-total__table-cell_tar efficiency-total__table-cell_body">
                                <span class="efficiency-total__table-value">
                                    {{restaurant.hall.avg_dish_price | currencyIntFormat}}
                                </span>
                            </td>
                            <td class="efficiency-total__table-cell efficiency-total__table-cell_tar efficiency-total__table-cell_body">
                                <span class="efficiency-total__table-value">
                                    {{restaurant.hall.avg_orders_per_courier | floatFormat}}
                                </span>
                            </td>

                            <td class="efficiency-total__table-cell efficiency-total__table-cell_tar efficiency-total__table-cell_body">
                                <span class="efficiency-total__table-value">
                                    {{restaurant.take.avg_guests_in_order | floatFormat}}
                                </span>
                            </td>
                            <td class="efficiency-total__table-cell efficiency-total__table-cell_tar efficiency-total__table-cell_body">
                                <span class="efficiency-total__table-value">
                                    {{restaurant.take.avg_check_per_guest | currencyIntFormat}}
                                </span>
                            </td>
                            <td class="efficiency-total__table-cell efficiency-total__table-cell_tar efficiency-total__table-cell_body">
                                <span class="efficiency-total__table-value">
                                    {{restaurant.take.avg_dish_per_guest | floatFormat}}
                                </span>
                            </td>
                            <td class="efficiency-total__table-cell efficiency-total__table-cell_tar efficiency-total__table-cell_body">
                                <span class="efficiency-total__table-value">
                                    {{restaurant.take.avg_dish_price | currencyIntFormat}}
                                </span>
                            </td>
                            <td class="efficiency-total__table-cell efficiency-total__table-cell_tar efficiency-total__table-cell_body">
                                <span class="efficiency-total__table-value">
                                    {{restaurant.take.avg_orders_per_courier | floatFormat}}
                                </span>
                            </td>

                            <td class="efficiency-total__table-cell efficiency-total__table-cell_tar efficiency-total__table-cell_body">
                                <span class="efficiency-total__table-value">
                                    {{restaurant.pickup.avg_guests_in_order | floatFormat}}
                                </span>
                            </td>
                            <td class="efficiency-total__table-cell efficiency-total__table-cell_tar efficiency-total__table-cell_body">
                                <span class="efficiency-total__table-value">
                                    {{restaurant.pickup.avg_check_per_guest | currencyIntFormat}}
                                </span>
                            </td>
                            <td class="efficiency-total__table-cell efficiency-total__table-cell_tar efficiency-total__table-cell_body">
                                <span class="efficiency-total__table-value">
                                    {{restaurant.pickup.avg_dish_per_guest | floatFormat}}
                                </span>
                            </td>
                            <td class="efficiency-total__table-cell efficiency-total__table-cell_tar efficiency-total__table-cell_body">
                                <span class="efficiency-total__table-value">
                                    {{restaurant.pickup.avg_dish_price | currencyIntFormat}}
                                </span>
                            </td>
                            <td class="efficiency-total__table-cell efficiency-total__table-cell_tar efficiency-total__table-cell_body">
                                <span class="efficiency-total__table-value">
                                    {{restaurant.pickup.avg_orders_per_courier | floatFormat}}
                                </span>
                            </td>

                            <td class="efficiency-total__table-cell efficiency-total__table-cell_tar efficiency-total__table-cell_body">
                                <span class="efficiency-total__table-value">
                                    {{restaurant.total.avg_guests_in_order | floatFormat}}
                                </span>
                            </td>
                            <td class="efficiency-total__table-cell efficiency-total__table-cell_tar efficiency-total__table-cell_body">
                                <span class="efficiency-total__table-value">
                                    {{restaurant.total.avg_check_per_guest | currencyIntFormat}}
                                </span>
                            </td>
                            <td class="efficiency-total__table-cell efficiency-total__table-cell_tar efficiency-total__table-cell_body">
                                <span class="efficiency-total__table-value">
                                    {{restaurant.total.avg_dish_per_guest | floatFormat}}
                                </span>
                            </td>
                            <td class="efficiency-total__table-cell efficiency-total__table-cell_tar efficiency-total__table-cell_body">
                                <span class="efficiency-total__table-value">
                                    {{restaurant.total.avg_dish_price | currencyIntFormat}}
                                </span>
                            </td>
                            <td class="efficiency-total__table-cell efficiency-total__table-cell_tar efficiency-total__table-cell_body">
                                <span class="efficiency-total__table-value">
                                    {{restaurant.total.avg_orders_per_courier | floatFormat}}
                                </span>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
        <div class="efficiency-total__compare-other-period" v-else-if="modeId === 'compareOtherPeriod' && compareEfficiency">
            <div class="efficiency-total__box box">
                <table class="efficiency-total__table">
                    <caption class="efficiency-total__table-caption">
                        <router-link class="link link_red"
                            :to="{ name: 'EfficiencyRestaurant', params: { id: 'total' } }"
                            title="Смотреть общую эффективность по всем ресторанам"
                        >
                            Итого
                        </router-link>
                    </caption>
                    <colgroup>
                        <col class="efficiency-total__table-column efficiency-total__table-column_title">
                        <col class="efficiency-total__table-column efficiency-total__table-column_average">
                        <col class="efficiency-total__table-column efficiency-total__table-column_average">
                        <col class="efficiency-total__table-column efficiency-total__table-column_average">
                        <col class="efficiency-total__table-column efficiency-total__table-column_average">
                        <col class="efficiency-total__table-column efficiency-total__table-column_average">
                        <col class="efficiency-total__table-column efficiency-total__table-column_average">
                        <col class="efficiency-total__table-column efficiency-total__table-column_average">
                        <col class="efficiency-total__table-column efficiency-total__table-column_average">
                        <col class="efficiency-total__table-column efficiency-total__table-column_average">
                        <col class="efficiency-total__table-column efficiency-total__table-column_average">
                        <col class="efficiency-total__table-column efficiency-total__table-column_average">
                        <col class="efficiency-total__table-column efficiency-total__table-column_average">
                        <col class="efficiency-total__table-column efficiency-total__table-column_average">
                        <col class="efficiency-total__table-column efficiency-total__table-column_average">
                        <col class="efficiency-total__table-column efficiency-total__table-column_average">
                    </colgroup>
                    <thead>
                        <tr class="efficiency-total__table-row efficiency-total__table-row_head">
                            <th class="efficiency-total__table-cell efficiency-total__table-cell_vat efficiency-total__table-cell_head" scope="col" rowspan="2">Источник</th>
                            <th class="efficiency-total__table-cell efficiency-total__table-cell_head efficiency-total__table-cell_tac" scope="col" colspan="5">Доставка</th>
                            <th class="efficiency-total__table-cell efficiency-total__table-cell_head efficiency-total__table-cell_tac" scope="col" colspan="5">Зал</th>
                            <th class="efficiency-total__table-cell efficiency-total__table-cell_head efficiency-total__table-cell_tac" scope="col" colspan="5">С собой</th>
                            <th class="efficiency-total__table-cell efficiency-total__table-cell_head efficiency-total__table-cell_tac" scope="col" colspan="5">Самовынос</th>
                            <th class="efficiency-total__table-cell efficiency-total__table-cell_head efficiency-total__table-cell_tac" scope="col" colspan="5">Всего</th>
                        </tr>
                        <tr class="efficiency-total__table-row efficiency-total__table-row_head">
                            <th class="efficiency-total__table-cell efficiency-total__table-cell_text-small efficiency-total__table-cell_head efficiency-total__table-cell_tar" scope="col">Гостей на заказ</th>
                            <th class="efficiency-total__table-cell efficiency-total__table-cell_text-small efficiency-total__table-cell_head efficiency-total__table-cell_tar" scope="col">Чек на гостя</th>
                            <th class="efficiency-total__table-cell efficiency-total__table-cell_text-small efficiency-total__table-cell_head efficiency-total__table-cell_tar" scope="col">Блюд на гостя</th>
                            <th class="efficiency-total__table-cell efficiency-total__table-cell_text-small efficiency-total__table-cell_head efficiency-total__table-cell_tar" scope="col">Цена блюда</th>
                            <th class="efficiency-total__table-cell efficiency-total__table-cell_text-small efficiency-total__table-cell_head efficiency-total__table-cell_tar" scope="col">Заказов на курьера</th>

                            <th class="efficiency-total__table-cell efficiency-total__table-cell_text-small efficiency-total__table-cell_head efficiency-total__table-cell_tar" scope="col">Гостей на заказ</th>
                            <th class="efficiency-total__table-cell efficiency-total__table-cell_text-small efficiency-total__table-cell_head efficiency-total__table-cell_tar" scope="col">Чек на гостя</th>
                            <th class="efficiency-total__table-cell efficiency-total__table-cell_text-small efficiency-total__table-cell_head efficiency-total__table-cell_tar" scope="col">Блюд на гостя</th>
                            <th class="efficiency-total__table-cell efficiency-total__table-cell_text-small efficiency-total__table-cell_head efficiency-total__table-cell_tar" scope="col">Цена блюда</th>
                            <th class="efficiency-total__table-cell efficiency-total__table-cell_text-small efficiency-total__table-cell_head efficiency-total__table-cell_tar" scope="col">Заказов на курьера</th>

                            <th class="efficiency-total__table-cell efficiency-total__table-cell_text-small efficiency-total__table-cell_head efficiency-total__table-cell_tar" scope="col">Гостей на заказ</th>
                            <th class="efficiency-total__table-cell efficiency-total__table-cell_text-small efficiency-total__table-cell_head efficiency-total__table-cell_tar" scope="col">Чек на гостя</th>
                            <th class="efficiency-total__table-cell efficiency-total__table-cell_text-small efficiency-total__table-cell_head efficiency-total__table-cell_tar" scope="col">Блюд на гостя</th>
                            <th class="efficiency-total__table-cell efficiency-total__table-cell_text-small efficiency-total__table-cell_head efficiency-total__table-cell_tar" scope="col">Цена блюда</th>
                            <th class="efficiency-total__table-cell efficiency-total__table-cell_text-small efficiency-total__table-cell_head efficiency-total__table-cell_tar" scope="col">Заказов на курьера</th>

                            <th class="efficiency-total__table-cell efficiency-total__table-cell_text-small efficiency-total__table-cell_head efficiency-total__table-cell_tar" scope="col">Гостей на заказ</th>
                            <th class="efficiency-total__table-cell efficiency-total__table-cell_text-small efficiency-total__table-cell_head efficiency-total__table-cell_tar" scope="col">Чек на гостя</th>
                            <th class="efficiency-total__table-cell efficiency-total__table-cell_text-small efficiency-total__table-cell_head efficiency-total__table-cell_tar" scope="col">Блюд на гостя</th>
                            <th class="efficiency-total__table-cell efficiency-total__table-cell_text-small efficiency-total__table-cell_head efficiency-total__table-cell_tar" scope="col">Цена блюда</th>
                            <th class="efficiency-total__table-cell efficiency-total__table-cell_text-small efficiency-total__table-cell_head efficiency-total__table-cell_tar" scope="col">Заказов на курьера</th>

                            <th class="efficiency-total__table-cell efficiency-total__table-cell_text-small efficiency-total__table-cell_head efficiency-total__table-cell_tar" scope="col">Гостей на заказ</th>
                            <th class="efficiency-total__table-cell efficiency-total__table-cell_text-small efficiency-total__table-cell_head efficiency-total__table-cell_tar" scope="col">Чек на гостя</th>
                            <th class="efficiency-total__table-cell efficiency-total__table-cell_text-small efficiency-total__table-cell_head efficiency-total__table-cell_tar" scope="col">Блюд на гостя</th>
                            <th class="efficiency-total__table-cell efficiency-total__table-cell_text-small efficiency-total__table-cell_head efficiency-total__table-cell_tar" scope="col">Цена блюда</th>
                            <th class="efficiency-total__table-cell efficiency-total__table-cell_text-small efficiency-total__table-cell_head efficiency-total__table-cell_tar" scope="col">Заказов на курьера</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr class="efficiency-total__table-row efficiency-total__table-row_body">
                            <td class="efficiency-total__table-cell efficiency-total__table-cell_body efficiency-total__table-cell_title">
                                {{reportDaterange | daterangeText}}
                            </td>

                            <td class="efficiency-total__table-cell efficiency-total__table-cell_body efficiency-total__table-cell_tar">
                                <span class="efficiency-total__table-value">
                                    {{reportEfficiency.total.delivery.avg_guests_in_order | floatFormat}}
                                </span>
                                <difference class="efficiency-total__table-value"
                                    v-if="compareEfficiency.total.delivery.avg_guests_in_order !== 0"
                                    :primaryValue="reportEfficiency.total.delivery.avg_guests_in_order"
                                    :compareValue="compareEfficiency.total.delivery.avg_guests_in_order"
                                    mode="percentage"
                                    filter="percentFloatFormat"
                                />
                            </td>
                            <td class="efficiency-total__table-cell efficiency-total__table-cell_body efficiency-total__table-cell_tar">
                                <span class="efficiency-total__table-value">
                                    {{reportEfficiency.total.delivery.avg_check_per_guest | currencyIntFormat}}
                                </span>
                                <difference class="efficiency-total__table-value"
                                    v-if="compareEfficiency.total.delivery.avg_check_per_guest !== 0"
                                    :primaryValue="reportEfficiency.total.delivery.avg_check_per_guest"
                                    :compareValue="compareEfficiency.total.delivery.avg_check_per_guest"
                                    mode="percentage"
                                    filter="percentFloatFormat"
                                />
                            </td>
                            <td class="efficiency-total__table-cell efficiency-total__table-cell_body efficiency-total__table-cell_tar">
                                <span class="efficiency-total__table-value">
                                    {{reportEfficiency.total.delivery.avg_dish_per_guest | floatFormat}}
                                </span>
                                <difference class="efficiency-total__table-value"
                                    v-if="compareEfficiency.total.delivery.avg_dish_per_guest !== 0"
                                    :primaryValue="reportEfficiency.total.delivery.avg_dish_per_guest"
                                    :compareValue="compareEfficiency.total.delivery.avg_dish_per_guest"
                                    mode="percentage"
                                    filter="percentFloatFormat"
                                />
                            </td>
                            <td class="efficiency-total__table-cell efficiency-total__table-cell_body efficiency-total__table-cell_tar">
                                <span class="efficiency-total__table-value">
                                    {{reportEfficiency.total.delivery.avg_dish_price | currencyIntFormat}}
                                </span>
                                <difference class="efficiency-total__table-value"
                                    v-if="compareEfficiency.total.delivery.avg_dish_price !== 0"
                                    :primaryValue="reportEfficiency.total.delivery.avg_dish_price"
                                    :compareValue="compareEfficiency.total.delivery.avg_dish_price"
                                    mode="percentage"
                                    filter="percentFloatFormat"
                                />
                            </td>
                            <td class="efficiency-total__table-cell efficiency-total__table-cell_body efficiency-total__table-cell_tar">
                                <span class="efficiency-total__table-value">
                                    {{reportEfficiency.total.delivery.avg_orders_per_courier | floatFormat}}
                                </span>
                                <difference class="efficiency-total__table-value"
                                    v-if="compareEfficiency.total.delivery.avg_orders_per_courier !== 0"
                                    :primaryValue="reportEfficiency.total.delivery.avg_orders_per_courier"
                                    :compareValue="compareEfficiency.total.delivery.avg_orders_per_courier"
                                    mode="percentage"
                                    filter="percentFloatFormat"
                                />
                            </td>

                            <td class="efficiency-total__table-cell efficiency-total__table-cell_body efficiency-total__table-cell_tar">
                                <span class="efficiency-total__table-value">
                                    {{reportEfficiency.total.hall.avg_guests_in_order | floatFormat}}
                                </span>
                                <difference class="efficiency-total__table-value"
                                    v-if="compareEfficiency.total.hall.avg_guests_in_order !== 0"
                                    :primaryValue="reportEfficiency.total.hall.avg_guests_in_order"
                                    :compareValue="compareEfficiency.total.hall.avg_guests_in_order"
                                    mode="percentage"
                                    filter="percentFloatFormat"
                                />
                            </td>
                            <td class="efficiency-total__table-cell efficiency-total__table-cell_body efficiency-total__table-cell_tar">
                                <span class="efficiency-total__table-value">
                                    {{reportEfficiency.total.hall.avg_check_per_guest | currencyIntFormat}}
                                </span>
                                <difference class="efficiency-total__table-value"
                                    v-if="compareEfficiency.total.hall.avg_check_per_guest !== 0"
                                    :primaryValue="reportEfficiency.total.hall.avg_check_per_guest"
                                    :compareValue="compareEfficiency.total.hall.avg_check_per_guest"
                                    mode="percentage"
                                    filter="percentFloatFormat"
                                />
                            </td>
                            <td class="efficiency-total__table-cell efficiency-total__table-cell_body efficiency-total__table-cell_tar">
                                <span class="efficiency-total__table-value">
                                    {{reportEfficiency.total.hall.avg_dish_per_guest | floatFormat}}
                                </span>
                                <difference class="efficiency-total__table-value"
                                    v-if="compareEfficiency.total.hall.avg_dish_per_guest !== 0"
                                    :primaryValue="reportEfficiency.total.hall.avg_dish_per_guest"
                                    :compareValue="compareEfficiency.total.hall.avg_dish_per_guest"
                                    mode="percentage"
                                    filter="percentFloatFormat"
                                />
                            </td>
                            <td class="efficiency-total__table-cell efficiency-total__table-cell_body efficiency-total__table-cell_tar">
                                <span class="efficiency-total__table-value">
                                    {{reportEfficiency.total.hall.avg_dish_price | currencyIntFormat}}
                                </span>
                                <difference class="efficiency-total__table-value"
                                    v-if="compareEfficiency.total.hall.avg_dish_price !== 0"
                                    :primaryValue="reportEfficiency.total.hall.avg_dish_price"
                                    :compareValue="compareEfficiency.total.hall.avg_dish_price"
                                    mode="percentage"
                                    filter="percentFloatFormat"
                                />
                            </td>
                            <td class="efficiency-total__table-cell efficiency-total__table-cell_body efficiency-total__table-cell_tar">
                                <span class="efficiency-total__table-value">
                                    {{reportEfficiency.total.hall.avg_orders_per_courier | floatFormat}}
                                </span>
                                <difference class="efficiency-total__table-value"
                                    v-if="compareEfficiency.total.hall.avg_orders_per_courier !== 0"
                                    :primaryValue="reportEfficiency.total.hall.avg_orders_per_courier"
                                    :compareValue="compareEfficiency.total.hall.avg_orders_per_courier"
                                    mode="percentage"
                                    filter="percentFloatFormat"
                                />
                            </td>

                            <td class="efficiency-total__table-cell efficiency-total__table-cell_body efficiency-total__table-cell_tar">
                                <span class="efficiency-total__table-value">
                                    {{reportEfficiency.total.take.avg_guests_in_order | floatFormat}}
                                </span>
                                <difference class="efficiency-total__table-value"
                                    v-if="compareEfficiency.total.take.avg_guests_in_order !== 0"
                                    :primaryValue="reportEfficiency.total.take.avg_guests_in_order"
                                    :compareValue="compareEfficiency.total.take.avg_guests_in_order"
                                    mode="percentage"
                                    filter="percentFloatFormat"
                                />
                            </td>
                            <td class="efficiency-total__table-cell efficiency-total__table-cell_body efficiency-total__table-cell_tar">
                                <span class="efficiency-total__table-value">
                                    {{reportEfficiency.total.take.avg_check_per_guest | currencyIntFormat}}
                                </span>
                                <difference class="efficiency-total__table-value"
                                    v-if="compareEfficiency.total.take.avg_check_per_guest !== 0"
                                    :primaryValue="reportEfficiency.total.take.avg_check_per_guest"
                                    :compareValue="compareEfficiency.total.take.avg_check_per_guest"
                                    mode="percentage"
                                    filter="percentFloatFormat"
                                />
                            </td>
                            <td class="efficiency-total__table-cell efficiency-total__table-cell_body efficiency-total__table-cell_tar">
                                <span class="efficiency-total__table-value">
                                    {{reportEfficiency.total.take.avg_dish_per_guest | floatFormat}}
                                </span>
                                <difference class="efficiency-total__table-value"
                                    v-if="compareEfficiency.total.take.avg_dish_per_guest !== 0"
                                    :primaryValue="reportEfficiency.total.take.avg_dish_per_guest"
                                    :compareValue="compareEfficiency.total.take.avg_dish_per_guest"
                                    mode="percentage"
                                    filter="percentFloatFormat"
                                />
                            </td>
                            <td class="efficiency-total__table-cell efficiency-total__table-cell_body efficiency-total__table-cell_tar">
                                <span class="efficiency-total__table-value">
                                    {{reportEfficiency.total.take.avg_dish_price | currencyIntFormat}}
                                </span>
                                <difference class="efficiency-total__table-value"
                                    v-if="compareEfficiency.total.take.avg_dish_price !== 0"
                                    :primaryValue="reportEfficiency.total.take.avg_dish_price"
                                    :compareValue="compareEfficiency.total.take.avg_dish_price"
                                    mode="percentage"
                                    filter="percentFloatFormat"
                                />
                            </td>
                            <td class="efficiency-total__table-cell efficiency-total__table-cell_body efficiency-total__table-cell_tar">
                                <span class="efficiency-total__table-value">
                                    {{reportEfficiency.total.take.avg_orders_per_courier | floatFormat}}
                                </span>
                                <difference class="efficiency-total__table-value"
                                    v-if="compareEfficiency.total.take.avg_orders_per_courier !== 0"
                                    :primaryValue="reportEfficiency.total.take.avg_orders_per_courier"
                                    :compareValue="compareEfficiency.total.take.avg_orders_per_courier"
                                    mode="percentage"
                                    filter="percentFloatFormat"
                                />
                            </td>

                            <td class="efficiency-total__table-cell efficiency-total__table-cell_body efficiency-total__table-cell_tar">
                                <span class="efficiency-total__table-value">
                                    {{reportEfficiency.total.pickup.avg_guests_in_order | floatFormat}}
                                </span>
                                <difference class="efficiency-total__table-value"
                                    v-if="compareEfficiency.total.pickup.avg_guests_in_order !== 0"
                                    :primaryValue="reportEfficiency.total.pickup.avg_guests_in_order"
                                    :compareValue="compareEfficiency.total.pickup.avg_guests_in_order"
                                    mode="percentage"
                                    filter="percentFloatFormat"
                                />
                            </td>
                            <td class="efficiency-total__table-cell efficiency-total__table-cell_body efficiency-total__table-cell_tar">
                                <span class="efficiency-total__table-value">
                                    {{reportEfficiency.total.pickup.avg_check_per_guest | currencyIntFormat}}
                                </span>
                                <difference class="efficiency-total__table-value"
                                    v-if="compareEfficiency.total.pickup.avg_check_per_guest !== 0"
                                    :primaryValue="reportEfficiency.total.pickup.avg_check_per_guest"
                                    :compareValue="compareEfficiency.total.pickup.avg_check_per_guest"
                                    mode="percentage"
                                    filter="percentFloatFormat"
                                />
                            </td>
                            <td class="efficiency-total__table-cell efficiency-total__table-cell_body efficiency-total__table-cell_tar">
                                <span class="efficiency-total__table-value">
                                    {{reportEfficiency.total.pickup.avg_dish_per_guest | floatFormat}}
                                </span>
                                <difference class="efficiency-total__table-value"
                                    v-if="compareEfficiency.total.pickup.avg_dish_per_guest !== 0"
                                    :primaryValue="reportEfficiency.total.pickup.avg_dish_per_guest"
                                    :compareValue="compareEfficiency.total.pickup.avg_dish_per_guest"
                                    mode="percentage"
                                    filter="percentFloatFormat"
                                />
                            </td>
                            <td class="efficiency-total__table-cell efficiency-total__table-cell_body efficiency-total__table-cell_tar">
                                <span class="efficiency-total__table-value">
                                    {{reportEfficiency.total.pickup.avg_dish_price | currencyIntFormat}}
                                </span>
                                <difference class="efficiency-total__table-value"
                                    v-if="compareEfficiency.total.pickup.avg_dish_price !== 0"
                                    :primaryValue="reportEfficiency.total.pickup.avg_dish_price"
                                    :compareValue="compareEfficiency.total.pickup.avg_dish_price"
                                    mode="percentage"
                                    filter="percentFloatFormat"
                                />
                            </td>
                            <td class="efficiency-total__table-cell efficiency-total__table-cell_body efficiency-total__table-cell_tar">
                                <span class="efficiency-total__table-value">
                                    {{reportEfficiency.total.pickup.avg_orders_per_courier | floatFormat}}
                                </span>
                                <difference class="efficiency-total__table-value"
                                    v-if="compareEfficiency.total.pickup.avg_orders_per_courier !== 0"
                                    :primaryValue="reportEfficiency.total.pickup.avg_orders_per_courier"
                                    :compareValue="compareEfficiency.total.pickup.avg_orders_per_courier"
                                    mode="percentage"
                                    filter="percentFloatFormat"
                                />
                            </td>

                            <td class="efficiency-total__table-cell efficiency-total__table-cell_body efficiency-total__table-cell_tar">
                                <span class="efficiency-total__table-value">
                                    {{reportEfficiency.total.total.avg_guests_in_order | floatFormat}}
                                </span>
                                <difference class="efficiency-total__table-value"
                                    v-if="compareEfficiency.total.total.avg_guests_in_order !== 0"
                                    :primaryValue="reportEfficiency.total.total.avg_guests_in_order"
                                    :compareValue="compareEfficiency.total.total.avg_guests_in_order"
                                    mode="percentage"
                                    filter="percentFloatFormat"
                                />
                            </td>
                            <td class="efficiency-total__table-cell efficiency-total__table-cell_body efficiency-total__table-cell_tar">
                                <span class="efficiency-total__table-value">
                                    {{reportEfficiency.total.total.avg_check_per_guest | currencyIntFormat}}
                                </span>
                                <difference class="efficiency-total__table-value"
                                    v-if="compareEfficiency.total.total.avg_check_per_guest !== 0"
                                    :primaryValue="reportEfficiency.total.total.avg_check_per_guest"
                                    :compareValue="compareEfficiency.total.total.avg_check_per_guest"
                                    mode="percentage"
                                    filter="percentFloatFormat"
                                />
                            </td>
                            <td class="efficiency-total__table-cell efficiency-total__table-cell_body efficiency-total__table-cell_tar">
                                <span class="efficiency-total__table-value">
                                    {{reportEfficiency.total.total.avg_dish_per_guest | floatFormat}}
                                </span>
                                <difference class="efficiency-total__table-value"
                                    v-if="compareEfficiency.total.total.avg_dish_per_guest !== 0"
                                    :primaryValue="reportEfficiency.total.total.avg_dish_per_guest"
                                    :compareValue="compareEfficiency.total.total.avg_dish_per_guest"
                                    mode="percentage"
                                    filter="percentFloatFormat"
                                />
                            </td>
                            <td class="efficiency-total__table-cell efficiency-total__table-cell_body efficiency-total__table-cell_tar">
                                <span class="efficiency-total__table-value">
                                    {{reportEfficiency.total.total.avg_dish_price | currencyIntFormat}}
                                </span>
                                <difference class="efficiency-total__table-value"
                                    v-if="compareEfficiency.total.total.avg_dish_price !== 0"
                                    :primaryValue="reportEfficiency.total.total.avg_dish_price"
                                    :compareValue="compareEfficiency.total.total.avg_dish_price"
                                    mode="percentage"
                                    filter="percentFloatFormat"
                                />
                            </td>
                            <td class="efficiency-total__table-cell efficiency-total__table-cell_body efficiency-total__table-cell_tar">
                                <span class="efficiency-total__table-value">
                                    {{reportEfficiency.total.total.avg_orders_per_courier | floatFormat}}
                                </span>
                                <difference class="efficiency-total__table-value"
                                    v-if="compareEfficiency.total.total.avg_orders_per_courier !== 0"
                                    :primaryValue="reportEfficiency.total.total.avg_orders_per_courier"
                                    :compareValue="compareEfficiency.total.total.avg_orders_per_courier"
                                    mode="percentage"
                                    filter="percentFloatFormat"
                                />
                            </td>
                        </tr>
                        <tr class="efficiency-total__table-row efficiency-total__table-row_body">
                            <td class="efficiency-total__table-cell efficiency-total__table-cell_body efficiency-total__table-cell_title">
                                {{compareDaterange | daterangeText}}
                            </td>

                            <td class="efficiency-total__table-cell efficiency-total__table-cell_body efficiency-total__table-cell_tar">
                                {{compareEfficiency.total.delivery.avg_guests_in_order | floatFormat}}
                            </td>
                            <td class="efficiency-total__table-cell efficiency-total__table-cell_body efficiency-total__table-cell_tar">
                                {{compareEfficiency.total.delivery.avg_check_per_guest | currencyIntFormat}}
                            </td>
                            <td class="efficiency-total__table-cell efficiency-total__table-cell_body efficiency-total__table-cell_tar">
                                {{compareEfficiency.total.delivery.avg_dish_per_guest | floatFormat}}
                            </td>
                            <td class="efficiency-total__table-cell efficiency-total__table-cell_body efficiency-total__table-cell_tar">
                                {{compareEfficiency.total.delivery.avg_dish_price | currencyIntFormat}}
                            </td>
                            <td class="efficiency-total__table-cell efficiency-total__table-cell_body efficiency-total__table-cell_tar">
                                {{compareEfficiency.total.delivery.avg_orders_per_courier | floatFormat}}
                            </td>

                            <td class="efficiency-total__table-cell efficiency-total__table-cell_body efficiency-total__table-cell_tar">
                                {{compareEfficiency.total.hall.avg_guests_in_order | floatFormat}}
                            </td>
                            <td class="efficiency-total__table-cell efficiency-total__table-cell_body efficiency-total__table-cell_tar">
                                {{compareEfficiency.total.hall.avg_check_per_guest | currencyIntFormat}}
                            </td>
                            <td class="efficiency-total__table-cell efficiency-total__table-cell_body efficiency-total__table-cell_tar">
                                {{compareEfficiency.total.hall.avg_dish_per_guest | floatFormat}}
                            </td>
                            <td class="efficiency-total__table-cell efficiency-total__table-cell_body efficiency-total__table-cell_tar">
                                {{compareEfficiency.total.hall.avg_dish_price | currencyIntFormat}}
                            </td>
                            <td class="efficiency-total__table-cell efficiency-total__table-cell_body efficiency-total__table-cell_tar">
                                {{compareEfficiency.total.hall.avg_orders_per_courier | floatFormat}}
                            </td>

                            <td class="efficiency-total__table-cell efficiency-total__table-cell_body efficiency-total__table-cell_tar">
                                {{compareEfficiency.total.take.avg_guests_in_order | floatFormat}}
                            </td>
                            <td class="efficiency-total__table-cell efficiency-total__table-cell_body efficiency-total__table-cell_tar">
                                {{compareEfficiency.total.take.avg_check_per_guest | currencyIntFormat}}
                            </td>
                            <td class="efficiency-total__table-cell efficiency-total__table-cell_body efficiency-total__table-cell_tar">
                                {{compareEfficiency.total.take.avg_dish_per_guest | floatFormat}}
                            </td>
                            <td class="efficiency-total__table-cell efficiency-total__table-cell_body efficiency-total__table-cell_tar">
                                {{compareEfficiency.total.take.avg_dish_price | currencyIntFormat}}
                            </td>
                            <td class="efficiency-total__table-cell efficiency-total__table-cell_body efficiency-total__table-cell_tar">
                                {{compareEfficiency.total.take.avg_orders_per_courier | floatFormat}}
                            </td>

                            <td class="efficiency-total__table-cell efficiency-total__table-cell_body efficiency-total__table-cell_tar">
                                {{compareEfficiency.total.pickup.avg_guests_in_order | floatFormat}}
                            </td>
                            <td class="efficiency-total__table-cell efficiency-total__table-cell_body efficiency-total__table-cell_tar">
                                {{compareEfficiency.total.pickup.avg_check_per_guest | currencyIntFormat}}
                            </td>
                            <td class="efficiency-total__table-cell efficiency-total__table-cell_body efficiency-total__table-cell_tar">
                                {{compareEfficiency.total.pickup.avg_dish_per_guest | floatFormat}}
                            </td>
                            <td class="efficiency-total__table-cell efficiency-total__table-cell_body efficiency-total__table-cell_tar">
                                {{compareEfficiency.total.pickup.avg_dish_price | currencyIntFormat}}
                            </td>
                            <td class="efficiency-total__table-cell efficiency-total__table-cell_body efficiency-total__table-cell_tar">
                                {{compareEfficiency.total.pickup.avg_orders_per_courier | floatFormat}}
                            </td>

                            <td class="efficiency-total__table-cell efficiency-total__table-cell_body efficiency-total__table-cell_tar">
                                {{compareEfficiency.total.total.avg_guests_in_order | floatFormat}}
                            </td>
                            <td class="efficiency-total__table-cell efficiency-total__table-cell_body efficiency-total__table-cell_tar">
                                {{compareEfficiency.total.total.avg_check_per_guest | currencyIntFormat}}
                            </td>
                            <td class="efficiency-total__table-cell efficiency-total__table-cell_body efficiency-total__table-cell_tar">
                                {{compareEfficiency.total.total.avg_dish_per_guest | floatFormat}}
                            </td>
                            <td class="efficiency-total__table-cell efficiency-total__table-cell_body efficiency-total__table-cell_tar">
                                {{compareEfficiency.total.total.avg_dish_price | currencyIntFormat}}
                            </td>
                            <td class="efficiency-total__table-cell efficiency-total__table-cell_body efficiency-total__table-cell_tar">
                                {{compareEfficiency.total.total.avg_orders_per_courier | floatFormat}}
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div class="efficiency-total__box box"
                v-for="restaurant in reportEfficiency.restaurants" :key="restaurant.id"
            >
                <table class="efficiency-total__table">
                    <caption class="efficiency-total__table-caption">
                        <router-link class="link link_red"
                            :to="{ name: 'EfficiencyRestaurant', params: { id: restaurant.id } }"
                            :title="`Смотреть эффективность ресторана ${restaurant.title}`">
                            {{restaurant.title}}
                        </router-link>
                    </caption>
                    <colgroup>
                        <col class="efficiency-total__table-column efficiency-total__table-column_title">
                        <col class="efficiency-total__table-column efficiency-total__table-column_average">
                        <col class="efficiency-total__table-column efficiency-total__table-column_average">
                        <col class="efficiency-total__table-column efficiency-total__table-column_average">
                        <col class="efficiency-total__table-column efficiency-total__table-column_average">
                        <col class="efficiency-total__table-column efficiency-total__table-column_average">
                        <col class="efficiency-total__table-column efficiency-total__table-column_average">
                        <col class="efficiency-total__table-column efficiency-total__table-column_average">
                        <col class="efficiency-total__table-column efficiency-total__table-column_average">
                        <col class="efficiency-total__table-column efficiency-total__table-column_average">
                        <col class="efficiency-total__table-column efficiency-total__table-column_average">
                        <col class="efficiency-total__table-column efficiency-total__table-column_average">
                        <col class="efficiency-total__table-column efficiency-total__table-column_average">
                        <col class="efficiency-total__table-column efficiency-total__table-column_average">
                        <col class="efficiency-total__table-column efficiency-total__table-column_average">
                        <col class="efficiency-total__table-column efficiency-total__table-column_average">
                    </colgroup>
                    <thead>
                        <tr class="efficiency-total__table-row efficiency-total__table-row_head">
                            <th class="efficiency-total__table-cell efficiency-total__table-cell_vat efficiency-total__table-cell_head" scope="col" rowspan="2">Источник</th>
                            <th class="efficiency-total__table-cell efficiency-total__table-cell_head efficiency-total__table-cell_tac" scope="col" colspan="5">Доставка</th>
                            <th class="efficiency-total__table-cell efficiency-total__table-cell_head efficiency-total__table-cell_tac" scope="col" colspan="5">Зал</th>
                            <th class="efficiency-total__table-cell efficiency-total__table-cell_head efficiency-total__table-cell_tac" scope="col" colspan="5">С собой</th>
                            <th class="efficiency-total__table-cell efficiency-total__table-cell_head efficiency-total__table-cell_tac" scope="col" colspan="5">Самовынос</th>
                            <th class="efficiency-total__table-cell efficiency-total__table-cell_head efficiency-total__table-cell_tac" scope="col" colspan="5">Всего</th>
                        </tr>
                        <tr class="efficiency-total__table-row efficiency-total__table-row_head">
                            <th class="efficiency-total__table-cell efficiency-total__table-cell_text-small efficiency-total__table-cell_head efficiency-total__table-cell_tar" scope="col">Гостей на заказ</th>
                            <th class="efficiency-total__table-cell efficiency-total__table-cell_text-small efficiency-total__table-cell_head efficiency-total__table-cell_tar" scope="col">Чек на гостя</th>
                            <th class="efficiency-total__table-cell efficiency-total__table-cell_text-small efficiency-total__table-cell_head efficiency-total__table-cell_tar" scope="col">Блюд на гостя</th>
                            <th class="efficiency-total__table-cell efficiency-total__table-cell_text-small efficiency-total__table-cell_head efficiency-total__table-cell_tar" scope="col">Цена блюда</th>
                            <th class="efficiency-total__table-cell efficiency-total__table-cell_text-small efficiency-total__table-cell_head efficiency-total__table-cell_tar" scope="col">Заказов на курьера</th>

                            <th class="efficiency-total__table-cell efficiency-total__table-cell_text-small efficiency-total__table-cell_head efficiency-total__table-cell_tar" scope="col">Гостей на заказ</th>
                            <th class="efficiency-total__table-cell efficiency-total__table-cell_text-small efficiency-total__table-cell_head efficiency-total__table-cell_tar" scope="col">Чек на гостя</th>
                            <th class="efficiency-total__table-cell efficiency-total__table-cell_text-small efficiency-total__table-cell_head efficiency-total__table-cell_tar" scope="col">Блюд на гостя</th>
                            <th class="efficiency-total__table-cell efficiency-total__table-cell_text-small efficiency-total__table-cell_head efficiency-total__table-cell_tar" scope="col">Цена блюда</th>
                            <th class="efficiency-total__table-cell efficiency-total__table-cell_text-small efficiency-total__table-cell_head efficiency-total__table-cell_tar" scope="col">Заказов на курьера</th>

                            <th class="efficiency-total__table-cell efficiency-total__table-cell_text-small efficiency-total__table-cell_head efficiency-total__table-cell_tar" scope="col">Гостей на заказ</th>
                            <th class="efficiency-total__table-cell efficiency-total__table-cell_text-small efficiency-total__table-cell_head efficiency-total__table-cell_tar" scope="col">Чек на гостя</th>
                            <th class="efficiency-total__table-cell efficiency-total__table-cell_text-small efficiency-total__table-cell_head efficiency-total__table-cell_tar" scope="col">Блюд на гостя</th>
                            <th class="efficiency-total__table-cell efficiency-total__table-cell_text-small efficiency-total__table-cell_head efficiency-total__table-cell_tar" scope="col">Цена блюда</th>
                            <th class="efficiency-total__table-cell efficiency-total__table-cell_text-small efficiency-total__table-cell_head efficiency-total__table-cell_tar" scope="col">Заказов на курьера</th>

                            <th class="efficiency-total__table-cell efficiency-total__table-cell_text-small efficiency-total__table-cell_head efficiency-total__table-cell_tar" scope="col">Гостей на заказ</th>
                            <th class="efficiency-total__table-cell efficiency-total__table-cell_text-small efficiency-total__table-cell_head efficiency-total__table-cell_tar" scope="col">Чек на гостя</th>
                            <th class="efficiency-total__table-cell efficiency-total__table-cell_text-small efficiency-total__table-cell_head efficiency-total__table-cell_tar" scope="col">Блюд на гостя</th>
                            <th class="efficiency-total__table-cell efficiency-total__table-cell_text-small efficiency-total__table-cell_head efficiency-total__table-cell_tar" scope="col">Цена блюда</th>
                            <th class="efficiency-total__table-cell efficiency-total__table-cell_text-small efficiency-total__table-cell_head efficiency-total__table-cell_tar" scope="col">Заказов на курьера</th>

                            <th class="efficiency-total__table-cell efficiency-total__table-cell_text-small efficiency-total__table-cell_head efficiency-total__table-cell_tar" scope="col">Гостей на заказ</th>
                            <th class="efficiency-total__table-cell efficiency-total__table-cell_text-small efficiency-total__table-cell_head efficiency-total__table-cell_tar" scope="col">Чек на гостя</th>
                            <th class="efficiency-total__table-cell efficiency-total__table-cell_text-small efficiency-total__table-cell_head efficiency-total__table-cell_tar" scope="col">Блюд на гостя</th>
                            <th class="efficiency-total__table-cell efficiency-total__table-cell_text-small efficiency-total__table-cell_head efficiency-total__table-cell_tar" scope="col">Цена блюда</th>
                            <th class="efficiency-total__table-cell efficiency-total__table-cell_text-small efficiency-total__table-cell_head efficiency-total__table-cell_tar" scope="col">Заказов на курьера</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr class="efficiency-total__table-row efficiency-total__table-row_body">
                            <td class="efficiency-total__table-cell efficiency-total__table-cell_body efficiency-total__table-cell_title">
                                {{reportDaterange | daterangeText}}
                            </td>
                            <td class="efficiency-total__table-cell efficiency-total__table-cell_body efficiency-total__table-cell_tar">
                                <span class="efficiency-total__table-value">
                                    {{restaurant.delivery.avg_guests_in_order | floatFormat}}
                                </span>
                                <difference class="efficiency-total__table-value"
                                    v-if="findRestaurant(compareEfficiency, restaurant.id) && findRestaurant(compareEfficiency, restaurant.id).delivery.avg_guests_in_order !== 0"
                                    :primaryValue="restaurant.delivery.avg_guests_in_order"
                                    :compareValue="findRestaurant(compareEfficiency, restaurant.id).delivery.avg_guests_in_order"
                                    mode="percentage"
                                    filter="percentFloatFormat"
                                />
                            </td>
                            <td class="efficiency-total__table-cell efficiency-total__table-cell_body efficiency-total__table-cell_tar">
                                <span class="efficiency-total__table-value">
                                    {{restaurant.delivery.avg_check_per_guest | currencyIntFormat}}
                                </span>
                                <difference class="efficiency-total__table-value"
                                    v-if="findRestaurant(compareEfficiency, restaurant.id) && findRestaurant(compareEfficiency, restaurant.id).delivery.avg_check_per_guest !== 0"
                                    :primaryValue="restaurant.delivery.avg_check_per_guest"
                                    :compareValue="findRestaurant(compareEfficiency, restaurant.id).delivery.avg_check_per_guest"
                                    mode="percentage"
                                    filter="percentFloatFormat"
                                />
                            </td>
                            <td class="efficiency-total__table-cell efficiency-total__table-cell_body efficiency-total__table-cell_tar">
                                <span class="efficiency-total__table-value">
                                    {{restaurant.delivery.avg_dish_per_guest | floatFormat}}
                                </span>
                                <difference class="efficiency-total__table-value"
                                    v-if="findRestaurant(compareEfficiency, restaurant.id) && findRestaurant(compareEfficiency, restaurant.id).delivery.avg_dish_per_guest !== 0"
                                    :primaryValue="restaurant.delivery.avg_dish_per_guest"
                                    :compareValue="findRestaurant(compareEfficiency, restaurant.id).delivery.avg_dish_per_guest"
                                    mode="percentage"
                                    filter="percentFloatFormat"
                                />
                            </td>
                            <td class="efficiency-total__table-cell efficiency-total__table-cell_body efficiency-total__table-cell_tar">
                                <span class="efficiency-total__table-value">
                                    {{restaurant.delivery.avg_dish_price | currencyIntFormat}}
                                </span>
                                <difference class="efficiency-total__table-value"
                                    v-if="findRestaurant(compareEfficiency, restaurant.id) && findRestaurant(compareEfficiency, restaurant.id).delivery.avg_dish_price !== 0"
                                    :primaryValue="restaurant.delivery.avg_dish_price"
                                    :compareValue="findRestaurant(compareEfficiency, restaurant.id).delivery.avg_dish_price"
                                    mode="percentage"
                                    filter="percentFloatFormat"
                                />
                            </td>
                            <td class="efficiency-total__table-cell efficiency-total__table-cell_body efficiency-total__table-cell_tar">
                                <span class="efficiency-total__table-value">
                                    {{restaurant.delivery.avg_orders_per_courier | floatFormat}}
                                </span>
                                <difference class="efficiency-total__table-value"
                                    v-if="findRestaurant(compareEfficiency, restaurant.id) && findRestaurant(compareEfficiency, restaurant.id).delivery.avg_orders_per_courier !== 0"
                                    :primaryValue="restaurant.delivery.avg_orders_per_courier"
                                    :compareValue="findRestaurant(compareEfficiency, restaurant.id).delivery.avg_orders_per_courier"
                                    mode="percentage"
                                    filter="percentFloatFormat"
                                />
                            </td>

                            <td class="efficiency-total__table-cell efficiency-total__table-cell_body efficiency-total__table-cell_tar">
                                <span class="efficiency-total__table-value">
                                    {{restaurant.hall.avg_guests_in_order | floatFormat}}
                                </span>
                                <difference class="efficiency-total__table-value"
                                    v-if="findRestaurant(compareEfficiency, restaurant.id) && findRestaurant(compareEfficiency, restaurant.id).hall.avg_guests_in_order !== 0"
                                    :primaryValue="restaurant.hall.avg_guests_in_order"
                                    :compareValue="findRestaurant(compareEfficiency, restaurant.id).hall.avg_guests_in_order"
                                    mode="percentage"
                                    filter="percentFloatFormat"
                                />
                            </td>
                            <td class="efficiency-total__table-cell efficiency-total__table-cell_body efficiency-total__table-cell_tar">
                                <span class="efficiency-total__table-value">
                                    {{restaurant.hall.avg_check_per_guest | currencyIntFormat}}
                                </span>
                                <difference class="efficiency-total__table-value"
                                    v-if="findRestaurant(compareEfficiency, restaurant.id) && findRestaurant(compareEfficiency, restaurant.id).hall.avg_check_per_guest !== 0"
                                    :primaryValue="restaurant.hall.avg_check_per_guest"
                                    :compareValue="findRestaurant(compareEfficiency, restaurant.id).hall.avg_check_per_guest"
                                    mode="percentage"
                                    filter="percentFloatFormat"
                                />
                            </td>
                            <td class="efficiency-total__table-cell efficiency-total__table-cell_body efficiency-total__table-cell_tar">
                                <span class="efficiency-total__table-value">
                                    {{restaurant.hall.avg_dish_per_guest | floatFormat}}
                                </span>
                                <difference class="efficiency-total__table-value"
                                    v-if="findRestaurant(compareEfficiency, restaurant.id) && findRestaurant(compareEfficiency, restaurant.id).hall.avg_dish_per_guest !== 0"
                                    :primaryValue="restaurant.hall.avg_dish_per_guest"
                                    :compareValue="findRestaurant(compareEfficiency, restaurant.id).hall.avg_dish_per_guest"
                                    mode="percentage"
                                    filter="percentFloatFormat"
                                />
                            </td>
                            <td class="efficiency-total__table-cell efficiency-total__table-cell_body efficiency-total__table-cell_tar">
                                <span class="efficiency-total__table-value">
                                    {{restaurant.hall.avg_dish_price | currencyIntFormat}}
                                </span>
                                <difference class="efficiency-total__table-value"
                                    v-if="findRestaurant(compareEfficiency, restaurant.id) && findRestaurant(compareEfficiency, restaurant.id).hall.avg_dish_price !== 0"
                                    :primaryValue="restaurant.hall.avg_dish_price"
                                    :compareValue="findRestaurant(compareEfficiency, restaurant.id).hall.avg_dish_price"
                                    mode="percentage"
                                    filter="percentFloatFormat"
                                />
                            </td>
                            <td class="efficiency-total__table-cell efficiency-total__table-cell_body efficiency-total__table-cell_tar">
                                <span class="efficiency-total__table-value">
                                    {{restaurant.hall.avg_orders_per_courier | floatFormat}}
                                </span>
                                <difference class="efficiency-total__table-value"
                                    v-if="findRestaurant(compareEfficiency, restaurant.id) && findRestaurant(compareEfficiency, restaurant.id).hall.avg_orders_per_courier !== 0"
                                    :primaryValue="restaurant.hall.avg_orders_per_courier"
                                    :compareValue="findRestaurant(compareEfficiency, restaurant.id).hall.avg_orders_per_courier"
                                    mode="percentage"
                                    filter="percentFloatFormat"
                                />
                            </td>

                            <td class="efficiency-total__table-cell efficiency-total__table-cell_body efficiency-total__table-cell_tar">
                                <span class="efficiency-total__table-value">
                                    {{restaurant.take.avg_guests_in_order | floatFormat}}
                                </span>
                                <difference class="efficiency-total__table-value"
                                    v-if="findRestaurant(compareEfficiency, restaurant.id) && findRestaurant(compareEfficiency, restaurant.id).take.avg_guests_in_order !== 0"
                                    :primaryValue="restaurant.take.avg_guests_in_order"
                                    :compareValue="findRestaurant(compareEfficiency, restaurant.id).take.avg_guests_in_order"
                                    mode="percentage"
                                    filter="percentFloatFormat"
                                />
                            </td>
                            <td class="efficiency-total__table-cell efficiency-total__table-cell_body efficiency-total__table-cell_tar">
                                <span class="efficiency-total__table-value">
                                    {{restaurant.take.avg_check_per_guest | currencyIntFormat}}
                                </span>
                                <difference class="efficiency-total__table-value"
                                    v-if="findRestaurant(compareEfficiency, restaurant.id) && findRestaurant(compareEfficiency, restaurant.id).take.avg_check_per_guest !== 0"
                                    :primaryValue="restaurant.take.avg_check_per_guest"
                                    :compareValue="findRestaurant(compareEfficiency, restaurant.id).take.avg_check_per_guest"
                                    mode="percentage"
                                    filter="percentFloatFormat"
                                />
                            </td>
                            <td class="efficiency-total__table-cell efficiency-total__table-cell_body efficiency-total__table-cell_tar">
                                <span class="efficiency-total__table-value">
                                    {{restaurant.take.avg_dish_per_guest | floatFormat}}
                                </span>
                                <difference class="efficiency-total__table-value"
                                    v-if="findRestaurant(compareEfficiency, restaurant.id) && findRestaurant(compareEfficiency, restaurant.id).take.avg_dish_per_guest !== 0"
                                    :primaryValue="restaurant.take.avg_dish_per_guest"
                                    :compareValue="findRestaurant(compareEfficiency, restaurant.id).take.avg_dish_per_guest"
                                    mode="percentage"
                                    filter="percentFloatFormat"
                                />
                            </td>
                            <td class="efficiency-total__table-cell efficiency-total__table-cell_body efficiency-total__table-cell_tar">
                                <span class="efficiency-total__table-value">
                                    {{restaurant.take.avg_dish_price | currencyIntFormat}}
                                </span>
                                <difference class="efficiency-total__table-value"
                                    v-if="findRestaurant(compareEfficiency, restaurant.id) && findRestaurant(compareEfficiency, restaurant.id).take.avg_dish_price !== 0"
                                    :primaryValue="restaurant.take.avg_dish_price"
                                    :compareValue="findRestaurant(compareEfficiency, restaurant.id).take.avg_dish_price"
                                    mode="percentage"
                                    filter="percentFloatFormat"
                                />
                            </td>
                            <td class="efficiency-total__table-cell efficiency-total__table-cell_body efficiency-total__table-cell_tar">
                                <span class="efficiency-total__table-value">
                                    {{restaurant.take.avg_orders_per_courier | floatFormat}}
                                </span>
                                <difference class="efficiency-total__table-value"
                                    v-if="findRestaurant(compareEfficiency, restaurant.id) && findRestaurant(compareEfficiency, restaurant.id).take.avg_orders_per_courier !== 0"
                                    :primaryValue="restaurant.take.avg_orders_per_courier"
                                    :compareValue="findRestaurant(compareEfficiency, restaurant.id).take.avg_orders_per_courier"
                                    mode="percentage"
                                    filter="percentFloatFormat"
                                />
                            </td>

                            <td class="efficiency-total__table-cell efficiency-total__table-cell_body efficiency-total__table-cell_tar">
                                <span class="efficiency-total__table-value">
                                    {{restaurant.pickup.avg_guests_in_order | floatFormat}}
                                </span>
                                <difference class="efficiency-total__table-value"
                                    v-if="findRestaurant(compareEfficiency, restaurant.id) && findRestaurant(compareEfficiency, restaurant.id).pickup.avg_guests_in_order !== 0"
                                    :primaryValue="restaurant.pickup.avg_guests_in_order"
                                    :compareValue="findRestaurant(compareEfficiency, restaurant.id).pickup.avg_guests_in_order"
                                    mode="percentage"
                                    filter="percentFloatFormat"
                                />
                            </td>
                            <td class="efficiency-total__table-cell efficiency-total__table-cell_body efficiency-total__table-cell_tar">
                                <span class="efficiency-total__table-value">
                                    {{restaurant.pickup.avg_check_per_guest | currencyIntFormat}}
                                </span>
                                <difference class="efficiency-total__table-value"
                                    v-if="findRestaurant(compareEfficiency, restaurant.id) && findRestaurant(compareEfficiency, restaurant.id).pickup.avg_check_per_guest !== 0"
                                    :primaryValue="restaurant.pickup.avg_check_per_guest"
                                    :compareValue="findRestaurant(compareEfficiency, restaurant.id).pickup.avg_check_per_guest"
                                    mode="percentage"
                                    filter="percentFloatFormat"
                                />
                            </td>
                            <td class="efficiency-total__table-cell efficiency-total__table-cell_body efficiency-total__table-cell_tar">
                                <span class="efficiency-total__table-value">
                                    {{restaurant.pickup.avg_dish_per_guest | floatFormat}}
                                </span>
                                <difference class="efficiency-total__table-value"
                                    v-if="findRestaurant(compareEfficiency, restaurant.id) && findRestaurant(compareEfficiency, restaurant.id).pickup.avg_dish_per_guest !== 0"
                                    :primaryValue="restaurant.pickup.avg_dish_per_guest"
                                    :compareValue="findRestaurant(compareEfficiency, restaurant.id).pickup.avg_dish_per_guest"
                                    mode="percentage"
                                    filter="percentFloatFormat"
                                />
                            </td>
                            <td class="efficiency-total__table-cell efficiency-total__table-cell_body efficiency-total__table-cell_tar">
                                <span class="efficiency-total__table-value">
                                    {{restaurant.pickup.avg_dish_price | currencyIntFormat}}
                                </span>
                                <difference class="efficiency-total__table-value"
                                    v-if="findRestaurant(compareEfficiency, restaurant.id) && findRestaurant(compareEfficiency, restaurant.id).pickup.avg_dish_price !== 0"
                                    :primaryValue="restaurant.pickup.avg_dish_price"
                                    :compareValue="findRestaurant(compareEfficiency, restaurant.id).pickup.avg_dish_price"
                                    mode="percentage"
                                    filter="percentFloatFormat"
                                />
                            </td>
                            <td class="efficiency-total__table-cell efficiency-total__table-cell_body efficiency-total__table-cell_tar">
                                <span class="efficiency-total__table-value">
                                    {{restaurant.pickup.avg_orders_per_courier | floatFormat}}
                                </span>
                                <difference class="efficiency-total__table-value"
                                    v-if="findRestaurant(compareEfficiency, restaurant.id) && findRestaurant(compareEfficiency, restaurant.id).pickup.avg_orders_per_courier !== 0"
                                    :primaryValue="restaurant.pickup.avg_orders_per_courier"
                                    :compareValue="findRestaurant(compareEfficiency, restaurant.id).pickup.avg_orders_per_courier"
                                    mode="percentage"
                                    filter="percentFloatFormat"
                                />
                            </td>

                            <td class="efficiency-total__table-cell efficiency-total__table-cell_body efficiency-total__table-cell_tar">
                                <span class="efficiency-total__table-value">
                                    {{restaurant.total.avg_guests_in_order | floatFormat}}
                                </span>
                                <difference class="efficiency-total__table-value"
                                    v-if="findRestaurant(compareEfficiency, restaurant.id) && findRestaurant(compareEfficiency, restaurant.id).total.avg_guests_in_order !== 0"
                                    :primaryValue="restaurant.total.avg_guests_in_order"
                                    :compareValue="findRestaurant(compareEfficiency, restaurant.id).total.avg_guests_in_order"
                                    mode="percentage"
                                    filter="percentFloatFormat"
                                />
                            </td>
                            <td class="efficiency-total__table-cell efficiency-total__table-cell_body efficiency-total__table-cell_tar">
                                <span class="efficiency-total__table-value">
                                    {{restaurant.total.avg_check_per_guest | currencyIntFormat}}
                                </span>
                                <difference class="efficiency-total__table-value"
                                    v-if="findRestaurant(compareEfficiency, restaurant.id) && findRestaurant(compareEfficiency, restaurant.id).total.avg_check_per_guest !== 0"
                                    :primaryValue="restaurant.total.avg_check_per_guest"
                                    :compareValue="findRestaurant(compareEfficiency, restaurant.id).total.avg_check_per_guest"
                                    mode="percentage"
                                    filter="percentFloatFormat"
                                />
                            </td>
                            <td class="efficiency-total__table-cell efficiency-total__table-cell_body efficiency-total__table-cell_tar">
                                <span class="efficiency-total__table-value">
                                    {{restaurant.total.avg_dish_per_guest | floatFormat}}
                                </span>
                                <difference class="efficiency-total__table-value"
                                    v-if="findRestaurant(compareEfficiency, restaurant.id) && findRestaurant(compareEfficiency, restaurant.id).total.avg_dish_per_guest !== 0"
                                    :primaryValue="restaurant.total.avg_dish_per_guest"
                                    :compareValue="findRestaurant(compareEfficiency, restaurant.id).total.avg_dish_per_guest"
                                    mode="percentage"
                                    filter="percentFloatFormat"
                                />
                            </td>
                            <td class="efficiency-total__table-cell efficiency-total__table-cell_body efficiency-total__table-cell_tar">
                                <span class="efficiency-total__table-value">
                                    {{restaurant.total.avg_dish_price | currencyIntFormat}}
                                </span>
                                <difference class="efficiency-total__table-value"
                                    v-if="findRestaurant(compareEfficiency, restaurant.id) && findRestaurant(compareEfficiency, restaurant.id).total.avg_dish_price !== 0"
                                    :primaryValue="restaurant.total.avg_dish_price"
                                    :compareValue="findRestaurant(compareEfficiency, restaurant.id).total.avg_dish_price"
                                    mode="percentage"
                                    filter="percentFloatFormat"
                                />
                            </td>
                            <td class="efficiency-total__table-cell efficiency-total__table-cell_body efficiency-total__table-cell_tar">
                                <span class="efficiency-total__table-value">
                                    {{restaurant.total.avg_orders_per_courier | floatFormat}}
                                </span>
                                <difference class="efficiency-total__table-value"
                                    v-if="findRestaurant(compareEfficiency, restaurant.id) && findRestaurant(compareEfficiency, restaurant.id).total.avg_orders_per_courier !== 0"
                                    :primaryValue="restaurant.total.avg_orders_per_courier"
                                    :compareValue="findRestaurant(compareEfficiency, restaurant.id).total.avg_orders_per_courier"
                                    mode="percentage"
                                    filter="percentFloatFormat"
                                />
                            </td>
                        </tr>
                        <tr class="efficiency-total__table-row efficiency-total__table-row_body"
                            v-if="findRestaurant(compareEfficiency, restaurant.id)"
                        >
                            <th class="efficiency-total__table-cell efficiency-total__table-cell_body" scope="row">
                                {{compareDaterange | daterangeText}}
                            </th>

                            <td class="efficiency-total__table-cell efficiency-total__table-cell_body efficiency-total__table-cell_tar">
                                {{findRestaurant(compareEfficiency, restaurant.id).delivery.avg_guests_in_order | floatFormat}}
                            </td>
                            <td class="efficiency-total__table-cell efficiency-total__table-cell_body efficiency-total__table-cell_tar">
                                {{findRestaurant(compareEfficiency, restaurant.id).delivery.avg_check_per_guest | currencyIntFormat}}
                            </td>
                            <td class="efficiency-total__table-cell efficiency-total__table-cell_body efficiency-total__table-cell_tar">
                                {{findRestaurant(compareEfficiency, restaurant.id).delivery.avg_dish_per_guest | floatFormat}}
                            </td>
                            <td class="efficiency-total__table-cell efficiency-total__table-cell_body efficiency-total__table-cell_tar">
                                {{findRestaurant(compareEfficiency, restaurant.id).delivery.avg_dish_price | currencyIntFormat}}
                            </td>
                            <td class="efficiency-total__table-cell efficiency-total__table-cell_body efficiency-total__table-cell_tar">
                                {{findRestaurant(compareEfficiency, restaurant.id).delivery.avg_orders_per_courier | floatFormat}}
                            </td>

                            <td class="efficiency-total__table-cell efficiency-total__table-cell_body efficiency-total__table-cell_tar">
                                {{findRestaurant(compareEfficiency, restaurant.id).hall.avg_guests_in_order | floatFormat}}
                            </td>
                            <td class="efficiency-total__table-cell efficiency-total__table-cell_body efficiency-total__table-cell_tar">
                                {{findRestaurant(compareEfficiency, restaurant.id).hall.avg_check_per_guest | currencyIntFormat}}
                            </td>
                            <td class="efficiency-total__table-cell efficiency-total__table-cell_body efficiency-total__table-cell_tar">
                                {{findRestaurant(compareEfficiency, restaurant.id).hall.avg_dish_per_guest | floatFormat}}
                            </td>
                            <td class="efficiency-total__table-cell efficiency-total__table-cell_body efficiency-total__table-cell_tar">
                                {{findRestaurant(compareEfficiency, restaurant.id).hall.avg_dish_price | currencyIntFormat}}
                            </td>
                            <td class="efficiency-total__table-cell efficiency-total__table-cell_body efficiency-total__table-cell_tar">
                                {{findRestaurant(compareEfficiency, restaurant.id).hall.avg_orders_per_courier | floatFormat}}
                            </td>

                            <td class="efficiency-total__table-cell efficiency-total__table-cell_body efficiency-total__table-cell_tar">
                                {{findRestaurant(compareEfficiency, restaurant.id).take.avg_guests_in_order | floatFormat}}
                            </td>
                            <td class="efficiency-total__table-cell efficiency-total__table-cell_body efficiency-total__table-cell_tar">
                                {{findRestaurant(compareEfficiency, restaurant.id).take.avg_check_per_guest | currencyIntFormat}}
                            </td>
                            <td class="efficiency-total__table-cell efficiency-total__table-cell_body efficiency-total__table-cell_tar">
                                {{findRestaurant(compareEfficiency, restaurant.id).take.avg_dish_per_guest | floatFormat}}
                            </td>
                            <td class="efficiency-total__table-cell efficiency-total__table-cell_body efficiency-total__table-cell_tar">
                                {{findRestaurant(compareEfficiency, restaurant.id).take.avg_dish_price | currencyIntFormat}}
                            </td>
                            <td class="efficiency-total__table-cell efficiency-total__table-cell_body efficiency-total__table-cell_tar">
                                {{findRestaurant(compareEfficiency, restaurant.id).take.avg_orders_per_courier | floatFormat}}
                            </td>

                            <td class="efficiency-total__table-cell efficiency-total__table-cell_body efficiency-total__table-cell_tar">
                                {{findRestaurant(compareEfficiency, restaurant.id).pickup.avg_guests_in_order | floatFormat}}
                            </td>
                            <td class="efficiency-total__table-cell efficiency-total__table-cell_body efficiency-total__table-cell_tar">
                                {{findRestaurant(compareEfficiency, restaurant.id).pickup.avg_check_per_guest | currencyIntFormat}}
                            </td>
                            <td class="efficiency-total__table-cell efficiency-total__table-cell_body efficiency-total__table-cell_tar">
                                {{findRestaurant(compareEfficiency, restaurant.id).pickup.avg_dish_per_guest | floatFormat}}
                            </td>
                            <td class="efficiency-total__table-cell efficiency-total__table-cell_body efficiency-total__table-cell_tar">
                                {{findRestaurant(compareEfficiency, restaurant.id).pickup.avg_dish_price | currencyIntFormat}}
                            </td>
                            <td class="efficiency-total__table-cell efficiency-total__table-cell_body efficiency-total__table-cell_tar">
                                {{findRestaurant(compareEfficiency, restaurant.id).pickup.avg_orders_per_courier | floatFormat}}
                            </td>

                            <td class="efficiency-total__table-cell efficiency-total__table-cell_body efficiency-total__table-cell_tar">
                                {{findRestaurant(compareEfficiency, restaurant.id).total.avg_guests_in_order | floatFormat}}
                            </td>
                            <td class="efficiency-total__table-cell efficiency-total__table-cell_body efficiency-total__table-cell_tar">
                                {{findRestaurant(compareEfficiency, restaurant.id).total.avg_check_per_guest | currencyIntFormat}}
                            </td>
                            <td class="efficiency-total__table-cell efficiency-total__table-cell_body efficiency-total__table-cell_tar">
                                {{findRestaurant(compareEfficiency, restaurant.id).total.avg_dish_per_guest | floatFormat}}
                            </td>
                            <td class="efficiency-total__table-cell efficiency-total__table-cell_body efficiency-total__table-cell_tar">
                                {{findRestaurant(compareEfficiency, restaurant.id).total.avg_dish_price | currencyIntFormat}}
                            </td>
                            <td class="efficiency-total__table-cell efficiency-total__table-cell_body efficiency-total__table-cell_tar">
                                {{findRestaurant(compareEfficiency, restaurant.id).total.avg_orders_per_courier | floatFormat}}
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </section>
</template>

<script>
    import { mapMutations } from "vuex";
    import { daterangeText } from "@/helpers/daterange";
    import { compareStrings, compareNumbers } from "@/helpers/compare";
    import Difference from "@/components/Difference";

    export default{
        name: "EfficiencyTotal",
        components: {
            Difference
        },
        props: {
            status: {
                type: String,
                required: true
            },
            reportDaterange: {
                type: Object,
                required: true
            },
            compareDaterange: {
                required: true
            },
            modes: {
                type: Array,
                required: true
            },
            modeId: {
                type: String,
                required: true
            },
            reportEfficiency: {
                required: true
            },
            compareEfficiency: {
                required: true
            },
            isReportTotalLinkVisible: {
                type: Boolean,
                required: true
            },
            isShowRestaurantsFilter: {
                type: Boolean,
                required: true
            },
            userRestaurants: {
                required: true
            }
        },
        data() {
            return {
                sorting: {
                    group: "total",
                    field: "avg_guests_in_order",
                    direction: "DESC"
                }
            };
        },
        computed: {
            reportRestaurants() {
                return this.reportEfficiency?.restaurants?.sort((restaurant1, restaurant2) => {
                    if (this.sorting.group === "common" && this.sorting.field === "title") {
                        return compareStrings(
                            restaurant1.title,
                            restaurant2.title,
                            this.sorting.direction
                        );
                    }

                    return compareNumbers(
                        restaurant1[this.sorting.group][this.sorting.field],
                        restaurant2[this.sorting.group][this.sorting.field],
                        this.sorting.direction
                    );
                });
            },
        },
        methods: {
            ...mapMutations([
                "toggleReportTotalLink",
                "toggleRestaurantsFilter"
            ]),
            findRestaurant(income, restaurantId) {
                return income?.restaurants?.find(({ id }) => id === restaurantId);
            },
            setSorting(group, field) {
                if (this.sorting.group === group && this.sorting.field === field) {
                    this.sorting.direction = this.sorting.direction === "ASC" ? "DESC" : "ASC";
                } else {
                    this.sorting.group = group;
                    this.sorting.field = field
                }
            },
        },
        filters: {
            daterangeText
        },
        created() {
            if (this.userRestaurants?.ids?.length === 1) {
                this.$router.push({
                    name: "EfficiencyRestaurant",
                    params:{
                        id: this.userRestaurants?.ids[0]
                    }
                });
            } else if (this.isReportTotalLinkVisible) {
                this.toggleReportTotalLink(false);
                this.toggleRestaurantsFilter(true);
            }
        }
    }
</script>

<style lang="scss">
    .efficiency-total__box {
        padding: 8px 16px 16px;
        overflow-x: auto;

        & + & {
            margin-top: 30px;
        }
    }
    .efficiency-total__table {
        width: 100%;
    }
    .efficiency-total__table-caption {
        padding: 8px 8px 0;
        font-size: 24px;
        font-weight: bold;
    }
    .efficiency-total__table-column {
        &_title {
            width: 12%;
        }

        &_average {
            width: calc(88% / 15);
        }
    }
    .efficiency-total__table-row {
        &_body {
            .efficiency-total__table_striped &:nth-of-type(odd) {
                background-color: $background-gray-primary;
            }

            &:hover {
                background-color: $gray-form;
            }
        }

        &_pinned {
            background-color: $gray-form !important;
        }
    }
    .efficiency-total__table-cell {
        font-size: 20px;

        &_text-small {
            font-size: 14px;
        }

        &_text-medium {
            font-size: 16px;
        }

        &_head {
            padding: 8px;
            white-space: nowrap;
        }

        &_body {
            padding: 16px 8px;
        }

        &_sortable {
            cursor: pointer;
        }

        &_title {
            font-weight: bold;
        }

        &_vat {
            vertical-align: top;
        }

        &_tar {
            text-align: right;
        }

        &_tac {
            text-align: center;
        }
    }
    .efficiency-total__table-value {
        display: block;
    }
    .efficiency-total__table-icon {
        width: 16px;
        height: 16px;
        vertical-align: middle;
    }
    .efficiency-total__table-text {
        vertical-align: middle;
    }
</style>
